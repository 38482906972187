@tailwind base;
@tailwind components;
@tailwind utilities;

[data-collapsible="true"].bg-white + [data-collapsible="true"].bg-white {
  @apply pt-0 md:pt-0;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New",
    monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%, #16abff33 0deg, #0885ff33 55deg, #54d6ff33 120deg, #0071ff33 160deg, transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080, #00000040, #00000030, #00000020, #00000010, #00000010, #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right, rgba(1, 65, 255, 0), rgba(1, 65, 255, 0), rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80, #ffffff40, #ffffff30, #ffffff20, #ffffff10, #ffffff10, #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  /* overflow-x: hidden; */
  font-size: 16px;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

@media only screen and (max-width: 768px) {
  body {
    margin-top: 89px !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  max-width: 1350px;
  margin: auto;
  padding: 75px;
  padding: 25px;
}

@media only screen and (min-width: 769px) {
  .container {
    padding: 45px;
  }
}

@media only screen and (min-width: 1025px) {
  .container {
    padding: 75px;
  }
}

/* @media only screen and (max-width: 1024px) {
  .container {
    max-width: 1290px;
    padding: 45px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    max-width: 1250px;
    padding: 25px;
  }
} */

/* .container {
  max-width: 1350px;
  margin: auto;
  padding: 75px;
}

@media only screen and (max-width: 1024px) {
  .container {
    max-width: 1290px;
    padding: 45px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    max-width: 1250px;
    padding: 25px;
  }
} */

/* 
.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 75px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    padding: 0 45px;
  }
} */

.wideContainer {
  max-width: 1400px;
  margin: auto;
}

.button {
  border: 1px solid white;
  padding: 12px 25px;
  /* padding: 18px 50px; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) !important;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 1px;
  border-radius: 0 !important;
}

.button:hover {
  background: rgba(255, 255, 255, 0.361);
  /* color: var(--chakra-colors-brand-black); */
}

.button--light {
  background-image: unset !important;
  background: white;
  border: 1px solid var(--chakra-colors-brand-black);
  color: var(--chakra-colors-brand-black);
}

.button--dark {
  background: var(--chakra-colors-brand-black);
  color: white;
  border: 1px solid var(--chakra-colors-brand-black);
}

.button--large {
  padding: 18px 50px;
  /* border: 2px solid; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 45px 0px;
}

.button--wide {
  width: 100%;
}

input {
  border-radius: 0 !important;
}

.linkUnderline {
  text-decoration: underline;
}

input,
textarea {
  /* border: 2px solid #c9c9c9 !important; */
  border: 1px solid var(--chakra-colors-gray-400) !important;
  border-radius: 5px !important;
}

.chakra-input__group input {
  padding-left: 40px;
}

/* p {
  font-size: calc(16px + 0.390625vw),
} */

h1 {
  font-size: calc(30px + 0.390625vw);
}

h2 {
  font-size: calc(30px + 0.390625vw);
  line-height: 52px;
}

.parallaxImageHelper {
  background-attachment: scroll;
}

/* For devices that do not support background-attachment: fixed */
/* The following styles will apply to iOS devices (iPhones and iPads) and other mobile devices */
/* Note: You can modify the pixel value according to your preference */
@supports (background-attachment: fixed) {
  /* Add your styles here for mobile devices that do not support background-attachment: fixed */
  .parallaxImageHelper {
    background-attachment: fixed;
  }
}

@media only screen and (max-width: 1024px) {
  .parallaxImageHelper {
    background-attachment: scroll;
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  .parallaxImageHelper {
    background-attachment: scroll;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .parallaxImageHelper {
    background-attachment: scroll;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* For landscape layouts only */
  .parallaxImageHelper {
    background-attachment: scroll;
  }
}
