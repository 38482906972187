.contactWrapper {
  /* padding: 75px 20px; */
  /* background: #e7e7e7; */
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.modalForm__button {
  width: 100%;
}

.split .fieldWrapper {
  width: calc(50% - 10px);
  flex-shrink: 0;
}

.split .fieldWrapper:first-of-type {
  margin-right: 10px;
}

.split .fieldWrapper:last-of-type {
  margin-left: 10px;
}

@media only screen and (min-width: 769px) {
}
