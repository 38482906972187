.modal {
  border-radius: 0 !important;
  max-width: 900px !important;
}

.modalHeader {
  padding: 0;
}

.modalBody {
  padding: 0 !important;
}

.modal__body {
  flex-direction: column;
}

.modalLeft {
  background-size: cover;
  background-position: center center;
  flex-shrink: 0;
  height: 270px;
  width: 100%;
}

.modal__closeButton {
  background-color: rgb(0 0 0 / 21%) !important;
  color: white;
}

.modal__closeButton:hover {
  background-color: rgb(0 0 0 / 36%) !important;
  color: white;
}

.modalRight {
  /* padding: 70px 50px;
   */
}

/* // Responsive
Background behind close button 
 */

/* // button fullwidth:  */

@media only screen and (min-width: 769px) {
  .modal__body {
    flex-direction: row;
    align-items: center;
  }

  .modalLeft {
    flex-shrink: 0;
    height: auto;
    width: 50%;
    min-height: 250px;
  }

  .modal__closeButton {
    background-color: rgb(0 0 0 / 21%) !important;
    color: black;
  }
}
